import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

//import drawer
import "react-drawer/lib/react-drawer.css";

//import component
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import TenantsMenu from "../CommonForBoth/TopbarDropdown/TenantsDropDown";

// Reactstrap

//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-colored.svg";
import logoLight from "../../assets/images/logo-white.png";
import { currentTenant, fullUrl } from "../../utils/Helpers";

//Import Icons

const Header = () => {
  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    }
    document.body.setAttribute("data-sidebar-size", "lg");
  }

  const tenant = currentTenant();

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="22" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div className="d-flex">
            <LanguageDropdown />

            <NotificationDropdown />
            <TenantsMenu />
            <ProfileMenu />
          </div>
        </div>
      </header>

      {/*<ReactDrawer open={openDrawer} onClose={onDrawerClose} position={position}>*/}
      {/*  <RightSidebar*/}
      {/*    onClose={onDrawerClose}*/}
      {/*  />*/}
      {/*</ReactDrawer>*/}
    </React.Fragment>
  );
};

export default withTranslation()(Header);
