import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { TenantFields } from "../../fields/TenantFields";
import { Endpoints } from "../../utils/Endpoints";
import { ITenant } from "../../interfaces/ITenant";
import { Divider } from "@material-ui/core";
import UpdateForm from "../../components/forms/UpdateForm";
import { Urls } from "../../routes/Urls";
import { TenantSchema } from "../../schemas/TenantSchema";

const Tenants = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const [tenants, setTenants] = useState<any>([])
  const [showTenantDialog, setShowTenantDialog] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("New Business")
  const [tenant, setTenant] = useState<any>()

  const history = useHistory()

  const handleTenantEdit = async (id: string) => {
    const { data } = await request.get(`${Endpoints.tenants}/${id}`, {})
    setTenant(data)
    setShowTenantDialog(true);
  }

  const handleOption = (type: string, id: any) => {
    if (type == "new") {
      setTenant({
        name: "",
        tenantcode: ""
      })
      setModalTitle("Add Business");
      setShowTenantDialog(true);
    } else if (type == "edit") {
      setModalTitle("Edit Business details");
      handleTenantEdit(id);
    } else if (type == "details") {
      history.push("/businesses/" + id);
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "category",
      text: "Type"
    },
    {
      dataField: "email",
      text: "Email Address"
    },
    {
      dataField: "sso_enabled",
      text: "SSO Enabled"
    },
    {
      dataField: "tenantcode",
      text: "Code"
    },
    {
      dataField: "tin",
      text: "TIN"
    },
    {
      dataField: "efrist",
      text: "EFRIS"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, tenant: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption("edit",tenant.id)}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption("details",tenant.id)}>
            <i className="mdi mdi-folder-open font-size-16 text-success me-1"></i>Details
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: tenants.length,
    totalSize: tenants.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();

  useEffect(() => {

    request.get(Endpoints.tenants, {})
      .then((response: AxiosResponse) => {
        const { tenants, totalRecords } = response.data;

        setTenants(tenants);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const initialValues: ITenant = {
    name: "",
    tenantcode: ""
  };

  const handleUpdate = (tenant: ITenant) => {

    const updatedTenants = tenants.map((p: ITenant) => {
      if (p.id === tenant.id){
        return {...p, ...tenant}
      }

      return p
    })

    setTenants(updatedTenants)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tenants - Treda</title>
        </MetaTags>

        <Container fluid>

          <XModal title={modalTitle}
                  width={"lg"}
                  open={showTenantDialog}
                  toggle={() => setShowTenantDialog(!showTenantDialog)}
                  onClose={() => setShowTenantDialog(false)}>

            <CreateForm
              fields={TenantFields}
              initialValues={tenant}
              submitEndpoint={Endpoints.tenants}
              submitButtonLabel={"Save"}
              validationSchema={TenantSchema}
              formData={true}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowTenantDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Tenants
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption("new",null)}>
                        Add tenant
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading tenants. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={tenants}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Tenants;