import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { fullUrl } from "../../utils/Helpers";

const request = new ApiCore();

const TenantDetails = () => {
  const { id } = useParams();
  const [tenant, setTenant] = useState<any>(null);

  useEffect(() => {
    loadTenant();
  }, []);

  const loadTenant = () => {
    request
      .get(`${Endpoints.tenants}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setTenant(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  return (
    <React.Fragment>
      {tenant && (
        <>
          <div className="page-content">
            <ToastContainer />
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <img
                            src={fullUrl(tenant.logo_path)}
                            alt="logo"
                            height="28"
                          />
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{tenant.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{tenant.email}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Address</div>
                              <div className="dt-value">{tenant.address}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Postal Address</div>
                              <div className="dt-value">{tenant.postaladdress}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Website</div>
                              <div className="dt-value">{tenant.web}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{tenant.phone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">TIN</div>
                              <div className="dt-value">{tenant.tin}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Mobile</div>
                              <div className="dt-value">{tenant.mobile}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Category</div>
                              <div className="dt-value">{tenant.category}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">EFRIS</div>
                              <div className="dt-value">{tenant.efrist}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">TIN</div>
                              <div className="dt-value">{tenant.tin}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Country</div>
                              <div className="dt-value">{tenant.country ? tenant.country.name: "N/A"}</div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default TenantDetails;
