import React, { useState } from "react";
import { Container, Row, Col, Label, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Urls } from "../../routes/Urls";

import { Formik, Field, Form } from "formik";

//import images
import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo-colored.svg";
import { IUser } from "../../interfaces/IUser";
import { SignupSchema } from "../../schemas/SignupSchema";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Endpoints } from "../../utils/Endpoints";
import { XSelect } from "../../components/common/inputs/XSelect";
import { ApiCore } from "../../helpers/api_helper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosResponse } from "axios";

const CreateAccount = () => {

  const user: IUser = {
    name: "",
    telephone: "",
    email: "",
    password: ""
  };

  const request = new ApiCore();


  const handleSubmit = (values: any) => {
    toast
    .promise(request.createFormData(`${Endpoints.signup}`, values), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          return "Your account has been created successfully.";
        },
      },
      error: {
        render({ data }) {
          return data;
        },
      },
    })
    .then((response: AxiosResponse) => {
      window.location.href = Urls.auth.login;
    });
  };

  const categories = [
    { value: "Retail", text: "Retail" },
    { value: "School", text: "School" },
    { value: "Services", text: "Services" },
    { value: "Farm", text: "Farm" },
    { value: "Hospital", text: "Hospital" },
  ];

  return (
    <React.Fragment>
      <MetaTags>
        <title>Create your Treda account</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <ToastContainer />
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={12} lg={8} xl={6}>
                <div className="py-1">
                  <div className="text-center mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5 className="">Create an account</h5>
                  </div>

                  <Formik validationSchema={SignupSchema}
                          initialValues={user}
                          onSubmit={(values) => handleSubmit(values)}>

                    {({isSubmitting, errors, touched}) => (
                      <Form>
                        <div className="signup-header">Company / Business Details</div>
                        <Row>
                          <Col>
                            <XTextBox                             
                              type="text"
                              key="tenant_name"
                              id="tenant_name"
                              name={"tenant_name"}
                              touched={touched}
                              placeholder="Enter business name"
                              errors={errors} />
                          </Col>
                          <Col>
                            <XTextBox                             
                                type="text"
                                key="tenant_email"
                                id="tenant_email"
                                name={"tenant_email"}
                                touched={touched}
                                placeholder="Enter business email"
                                errors={errors} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <XSelect
                              key="tenant_category"
                              id="tenant_category"
                              name={"tenant_category"}
                              placeholder="Category"
                              options={categories}
                              touched={touched}
                              errors={errors}
                            />
                          </Col>
                          <Col>                          
                            <XSelect
                              key="country_id"
                              id="country_id"
                              name={"tenant_country"}
                              dataSrc={Endpoints.countries}
                              dataType={"countries"}
                              placeholder="Country"
                              touched={touched}
                            />
                          </Col>
                        </Row>
                        <hr/>
                        <div className="signup-header">Admin user details</div>
                        <Row>
                          <Col>
                            <XTextBox                             
                              type="text"
                              key="name"
                              id="name"
                              name={"name"}
                              touched={touched}
                              placeholder="First and last name"
                              errors={errors} />
                          </Col>
                          <Col>
                            <XTextBox                             
                                type="text"
                                key="email"
                                id="email"
                                name={"email"}
                                touched={touched}
                                placeholder="Admin address email"
                                errors={errors} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <XTextBox                             
                                type="password"
                                key="password"
                                id="password"
                                name={"password"}
                                touched={touched}
                                placeholder="Password"
                                errors={errors} />
                          </Col>
                          <Col>                          
                          <XTextBox                             
                              type="password"
                              key="c_password"
                              id="c_password"
                              name={"c_password"}
                              touched={touched}
                              placeholder="Confirm password"
                              errors={errors} />
                          </Col>
                        </Row>
                        <div className="mt-3">
                          <Button color="primary"
                                  className="btn btn-primary w-100" type="submit">
                            {isSubmitting ? <Spinner size={"sm"}></Spinner> : "Create account"}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>


                  <div className="mt-5 text-center">
                    <p>Already have an account?<Link to="login"
                                                     className="fw-medium text-decoration-underline"> Signin </Link></p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy; {new Date().getFullYear()} Treda.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateAccount;