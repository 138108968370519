import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { RoleFields } from "../../fields/RoleFields";
import { Endpoints } from "../../utils/Endpoints";
import { Divider } from "@material-ui/core";
import UpdateForm from "../../components/forms/UpdateForm";
import { Urls } from "../../routes/Urls";
import { RoleSchema } from "../../schemas/RoleSchema";

const Roles = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const [roles, setRoles] = useState<any>([])
  const [showRoleDialog, setShowRoleDialog] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("New Role")
  const [role, setRole] = useState<any>()

  const history = useHistory()

  const handleOption = (type: string, role: any) => {
    if (type == "new") {
      setRole({
        name: "",
        description: ""
      })
      setModalTitle("Add Role");
      setShowRoleDialog(true);
    } else if (type == "edit") {
      setModalTitle("Edit Role");
      setRole(role);
      setShowRoleDialog(true);
    } else if (type == "details") {
      history.push("/roles/" + role.id);
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name"
    },{
      dataField: "description",
      text: "Description"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, role: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption("edit",role)}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption("details",role)}>
            <i className="mdi mdi-folder-open font-size-16 text-success me-1"></i>Details
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: roles.length,
    totalSize: roles.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();

  useEffect(() => {

    request.get(Endpoints.roles, {})
      .then((response: AxiosResponse) => {
        const { roles, totalRecords } = response.data;

        setRoles(roles);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const initialValues: any = {
    name: "",
    rolecode: ""
  };

  const handleUpdate = (role: any) => {

    const updatedRoles = roles.map((p: any) => {
      if (p.id === role.id){
        return {...p, ...role}
      }

      return p
    })

    setRoles(updatedRoles)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles - Treda</title>
        </MetaTags>

        <Container fluid>

          <XModal title={modalTitle}
                  width={"lg"}
                  open={showRoleDialog}
                  toggle={() => setShowRoleDialog(!showRoleDialog)}
                  onClose={() => setShowRoleDialog(false)}>

            <CreateForm
              fields={RoleFields}
              initialValues={role}
              submitEndpoint={Endpoints.roles}
              submitButtonLabel={"Save"}
              validationSchema={RoleSchema}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowRoleDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Roles
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption("new",null)}>
                        Add role
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading roles. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={roles}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Roles;