import { Field } from "formik";
import { Col, Label } from "reactstrap";
import React from "react";
import { ITextBoxProps } from "../../../interfaces/inputs/ITextBoxProps";

export const XTextBox = (props: ITextBoxProps) => {

  const isTouched = props.touched && props.touched[props.name];
  const hasErrors = props.errors && props.errors[props.name];

  return (
    <Col {...props.size}>
      <div className={`mb-3 ${props.icon ? "form-floating form-floating-custom" : ""}`}>

        {!props.icon && props.label &&
          <Label className={hasErrors && "text-danger"}
                 htmlFor={props.id}>{props.label}</Label>
        }

        <Field name={props.name} type={props.type}
               className={`form-control ${hasErrors && "is-invalid"}`}
               // value={props.value || ''}
               id={props.id}
               readOnly={props.readonly ? true : false}
               onKeyDown={props.onEnter}
               onBlur={props.handleBlur}
               placeholder={props.placeholder} />

        {/*{hasErrors && <small className={"text-danger"}>*/}
        {/*  {props.errors[props.name]}*/}
        {/*</small>}*/}

        {props.icon &&
          <>
            <Label className={hasErrors && "text-danger"}
                   htmlFor={props.id}>{props.label}</Label>
            <div className="form-floating-icon">
              <i className={`fa ${props.icon} ${hasErrors && "text-danger"}`}></i>
            </div>
          </>
        }
      </div>
    </Col>
  );
};