import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { UserFields } from "../../fields/UserFields";
import { Endpoints } from "../../utils/Endpoints";
import { UserSchema } from "../../schemas/UserSchema";
import { FieldArray, Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XFileInput } from "../../components/common/inputs/XFileInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";

const Users = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
  const [users, setUsers] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("New User");
  const [user, setUser] = useState<any>();
  const params = new URLSearchParams(useLocation().search);

  const history = useHistory();

  const handleOption = (type: string, user: any) => {
    if (type == "new") {
      setUser({
        name: "",
        usercode: "",
      });
      setModalTitle("Add User");
      setShowUserDialog(true);
    } else if (type == "edit") {
      setModalTitle("Edit user details");
      setUser(user);
      setShowUserDialog(true);
    } else if (type == "details") {
      history.push("/users/" + user.id);
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email Address",
    },
    {
      dataField: "sso_enabled",
      text: "SSO Enabled",
    },
    {
      dataField: "roles",
      text: "Role(s)",
      formatter: (cellContent: any, user: any) => (
        <div>
        {user.roles.map((role: any) => {
          return <>
            <i className="mdi mdi-check-circle font-size-16 text-primary me-1"></i>
            {role.name}
          </>
        })}
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, user: any) => (
        <UncontrolledDropdown>
          <DropdownToggle className="card-drop" tag="a">
            <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem to="#" onClick={() => handleOption("edit", user)}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
              Edit
            </DropdownItem>
            <DropdownItem to="#" onClick={() => handleOption("details", user)}>
              <i className="mdi mdi-folder-open font-size-16 text-success me-1"></i>
              Details
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: users.length,
    totalSize: users.length,
    custom: true,
  };

  const selectRow: any = {
    mode: "checkbox",
  };

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.users, params.get("inactive") ? {params: {inactive: true}} : {})
      .then((response: AxiosResponse) => {
        const { users, totalRecords } = response.data;

        setUsers(users);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    request
      .get(Endpoints.roles, {})
      .then((response: AxiosResponse) => {
        const { roles, totalRecords } = response.data;

        setRoles(roles);
        setLoadingRoles(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (values: any) => {
    if (values.id) {
      toast
      .promise(request.updateFormData(`${Endpoints.users}/${values.id}`, values), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "The user has been updated successfully";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        handleUpdate(response.data);
      });
    } else {
      toast
      .promise(request.createFormData(`${Endpoints.users}`, values), {
        pending: "Please wait..",
        success: {
          render({ data }) {
            return "The user has been added successfully";
          },
        },
        error: {
          render({ data }) {
            return data;
          },
        },
      })
      .then((response: AxiosResponse) => {
        handleUpdate(response.data);
      });
    }
  };

  const handleUpdate = (user: any) => {
    const updatedUsers = users.map((p: any) => {
      if (p.id === user.id) {
        return { ...p, ...user };
      }

      return p;
    });

    setUsers(updatedUsers);
  };

  const modules = [
    { value: "Accounting", text: "Accounting" },
    { value: "Academics", text: "Academics" },
    { value: "Inventory", text: "Inventory" },
    { value: "Medical", text: "Medical" },
    { value: "Human Resource", text: "Human Resource" },
  ];

  const getInitialValues = () => {
    const v = user;
    try {
      if (v && v.roles) {
        v.roles = v.roles.map((role: any) => role.id)
      }
    } catch (err: any) {
      
    }
    return v;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users - Treda</title>
        </MetaTags>

        <Container fluid>
          <XModal
            title={modalTitle}
            width={"lg"}
            open={showUserDialog}
            toggle={() => setShowUserDialog(!showUserDialog)}
            onClose={() => setShowUserDialog(false)}
          >
            <Formik
              initialValues={getInitialValues()}
              enableReinitialize
              validateOnBlur
              onSubmit={(values: any) => handleSubmit(values)}
            >
              {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                <>
                  <Form>
                    <div className="modal-body">
                      <Row>
                        <Col lg={4}>
                          <XTextBox
                            type="text"
                            key="name"
                            id="name"
                            name={"name"}
                            label={"Name"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XTextBox
                            type="text"
                            key="email"
                            id="email"
                            name={"email"}
                            label={"Email"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XTextBox
                            type="text"
                            key="phone"
                            id="phone"
                            name={"phone"}
                            label={"Phone"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XSelect
                            key="defaultmodule"
                            id="defaultmodule"
                            name={"defaultmodule"}
                            options={modules}
                            label={"Default Module"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XSelect
                            key="location_id"
                            id="location_id"
                            name={"location_id"}
                            dataSrc={Endpoints.locations}
                            dataType={"locations"}
                            label={"Location"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XTextBox
                            type="text"
                            key="staffno"
                            id="staffno"
                            name={"staffno"}
                            label={"Staff No."}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XTextBox
                            type="text"
                            key="nssfno"
                            id="nssfno"
                            name={"nssfno"}
                            label={"NSSF No."}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={4}>
                          <XTextBox
                            type="text"
                            key="accountno"
                            id="accountno"
                            name={"accountno"}
                            label={"Account No."}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XTextBox
                            type="text"
                            key="initials"
                            id="initials"
                            name={"initials"}
                            label={"Initials"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XCheckbox
                            key="admin"
                            id="admin"
                            name={"admin"}
                            label={"Admin"}
                            value={"true"}
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.admin}
                            size={{}}
                          />
                        </Col>
                        <Col lg={2}>
                          <XCheckbox
                            key="sso_enabled"
                            id="sso_enabled"
                            name={"sso_enabled"}
                            label={"Enable SSO"}
                            value={"true"}
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.sso_enabled}
                            size={{}}
                          />
                        </Col>
                        <Col lg={2}>
                          <XCheckbox
                            key="payroll"
                            id="payroll"
                            name={"payroll"}
                            label={"Payroll"}
                            value={"true"}
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.payroll}
                            size={{}}
                          />
                        </Col>
                        <Col lg={3}>
                          <XFileInput
                            key="avatar"
                            id="avatar"
                            name={"avatar"}
                            label={"Photo"}
                            onChange={(event: any) => {
                              setFieldValue(
                                "avatar",
                                event.currentTarget.files[0]
                              );
                            }}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={2}>
                          <XCheckbox
                            key="kactive"
                            id="kactive"
                            name={"active"}
                            label={"Active"}
                            value={"true"}
                            includeTopPadding={true}
                            onChange={setFieldValue}
                            checked={values.active}
                            size={{}}
                          />
                        </Col>
                        <XTextBox
                            type="password"
                            key="password"
                            id="password"
                            name={"password"}
                            label={"Password"}
                            touched={touched}
                            errors={errors}
                          />
                      </Row>
                      <hr/>
                      <FieldArray
                        name="roles"
                        render={(arrayHelpers) => (
                          <Row>
                            {roles &&
                              roles.map((item: any, index: number) => {
                                return (
                                  <Col lg={2} key={index}>
                                    <XCheckbox
                                      key={`roles.${index}`}
                                      id={`roles.${index}`}
                                      name={`roles.${index}`}
                                      label={item.name}
                                      value={item.id}
                                      includeTopPadding={false}
                                      checked={values.roles && values.roles.includes(item.id)}
                                      onChange={(field: any,value: any) => {
                                        if (value && value > 0) {
                                          arrayHelpers.push(item.id);
                                        } else {
                                          if (values.roles) {
                                            const idx = values.roles.indexOf(item.id);
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                      }}
                                      size={{}}
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                        )}
                      />
                      <hr/>
                      <Col lg={2}>
                        <div>&nbsp;</div>
                        <Button
                          disabled={false}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                        </Button>
                      </Col>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">Users</CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        to="#"
                        onClick={() => handleOption("new", null)}
                      >
                        Add user
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <ToastContainer />
                  {(loading || loadingRoles) && <Loading label="Loading users. Please wait." />}
                  {!loading && !loadingRoles && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={users}
                          search
                          bootstrap4
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
