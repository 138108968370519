import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'Primary', text: 'Primary'},
  {value: 'Secondary', text: 'Secondary'},
]

const terms = [
  {value: 'I', text: 'I'},
  {value: 'II', text: 'II'},
  {value: 'III', text: 'III'},
]

export const SettingFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 6}, position: 1 },
  { name: "year", type: "text", label: "Current year", size: {lg: 3}, position: 2 },
  { name: "term", type: "select", label: "Current term", options: terms, position: 3, size: {lg: 3} },
  { name: "category", type: "select", label: "School Type", options: categories, position: 4, size: {lg: 6} },
  { name: "address", type: "text", label: "Address", size: {lg: 6}, position: 5 },
  { name: "motto", type: "text", label: "Motto", size: {lg: 4}, position: 12 },
  { name: "automarkcomment", type: "checkbox", label: "Auto subject comments", size: {lg: 4}, position: 7, value: true, includeTopPadding: true },
  { name: "autoreportcomment", type: "checkbox", label: "Auto report comments", size: {lg: 4}, position: 8, value: true, includeTopPadding: true },
  { name: "wildcardsubjectteacher", type: "checkbox", label: "Subject teachers can enter all marks", size: {lg: 4}, position: 9, value: true, includeTopPadding: true },
  { name: "position", type: "checkbox", label: "Display position", size: {lg: 4}, position: 9, value: true, includeTopPadding: true },
  { name: "displayinitials", type: "checkbox", label: "Display Initials", size: {lg: 4}, position: 10, value: true, includeTopPadding: true },
  { name: "disablereportheader", type: "checkbox", label: "Hide report header", size: {lg: 4}, position: 11, value: true, includeTopPadding: true },
  { name: "reportbackground", type: "checkbox", label: "Display background", size: {lg: 4}, position: 12, value: true, includeTopPadding: true },
  { name: "target", type: "checkbox", label: "Include target on report", size: {lg: 4}, position: 13, value: true, includeTopPadding: true },
  { name: "subjectcomment", type: "checkbox", label: "Display subject comment", size: {lg: 4}, position: 14, value: true, includeTopPadding: true },
  { name: "signature", type: "checkbox", label: "Include signature", size: {lg: 4}, position: 15, value: true, includeTopPadding: true },
  { name: "firstnamefirst", type: "checkbox", label: "First name First", size: {lg: 4}, position: 16, value: true, includeTopPadding: true },
  { name: "numericalgrade", type: "checkbox", label: "Numerical grades", size: {lg: 4}, position: 17, value: true, includeTopPadding: true },
  { name: "includewatermark", type: "checkbox", label: "Include watermark", size: {lg: 4}, position: 18, value: true, includeTopPadding: true },
  { name: "displaydeputy", type: "checkbox", label: "Display deputy", size: {lg: 4}, position: 19, value: true, includeTopPadding: true },
  { name: "uniformolevelreport", type: "checkbox", label: "Uniform O-Level report", size: {lg: 4}, position: 20, value: true, includeTopPadding: true },
  { name: "reportheaderheight", type: "text", label: "Header height", size: {lg: 4}, position: 21 },
  { name: "hideheadteacher", type: "checkbox", label: "Hide head teacher", size: {lg: 4}, position: 22, value: true, includeTopPadding: true },
  { name: "differentp1p2", type: "checkbox", label: "Different P1 & P2 report", size: {lg: 4}, position: 23, value: true, includeTopPadding: true },
  { name: "disable_mark_edit", type: "checkbox", label: "Disable editing marks by teachers", size: {lg: 4}, position: 24, value: true, includeTopPadding: true },
  { name: "hide_logo", type: "checkbox", label: "Hide logo", size: {lg: 4}, position: 25, value: true, includeTopPadding: true },
  { name: "offical_cumm_report", type: "checkbox", label: "Official cummulative report", size: {lg: 4}, position: 26, value: true, includeTopPadding: true },
  { name: "subsidiary", type: "text", label: "Display subsidiaries", size: {lg: 4}, position: 27 },
  { name: "fontsize", type: "text", label: "Font size", size: {lg: 4}, position: 28 },
  { name: "headteacher", type: "text", label: "Head teacher", size: {lg: 4}, position: 29 },
  { name: "new_curriculum_forms", type: "text", label: "New curriculum classes", size: {lg: 4}, position: 30 },
  { name: "hide_marks", type: "text", label: "Hide marks for", size: {lg: 4}, position: 31 },
  { name: "assessment_categories", type: "text", label: "Extra assessment categories", size: {lg: 4}, position: 32 },
].sort((a: IField, b: IField) => a.position - b.position);