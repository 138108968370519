import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'Retail', text: 'Retail'},
  {value: 'School', text: 'School'},
  {value: 'Hospital', text: 'Hospital'},
  {value: 'Farm', text: 'Farm'},
  {value: 'Microfinance', text: 'Microfinance'},
  {value: 'Services', text: 'Services'},
]

export const TenantFields: IField[] = [
  { name: "name", type: "text", label: "Business Name", size: {lg: 6}, position: 1 },
  { name: "tenantcode", type: "text", label: "Tenant Code", size: {lg: 3}, position: 2, readonly: true },
  { name: "email", type: "text", label: "Email", size: {lg: 3}, position: 3 },
  { name: "category", type: "select", label: "Business Type", options: categories, position: 4, size: {lg: 6} },
  { name: "address", type: "text", label: "Address", size: {lg: 3}, position: 5 },
  { name: "web", type: "text", label: "Web", size: {lg: 3}, position: 6 },
  { name: "phone", type: "text", label: "Phone", size: {lg: 3}, position: 7 },
  { name: "tin", type: "text", label: "TIN", size: {lg: 3}, position: 8 },
  { name: "efrist", type: "checkbox", label: "Efris", size: {lg: 3}, position: 9, value: true, includeTopPadding: true },
  { name: "sso_enabled", type: "checkbox", label: "SSO Enabled", size: {lg: 3}, position: 9, value: true, includeTopPadding: true },
  { name: "logo", type: "file", label: "Logo", size: {lg: 6}, position: 10 },
  { name: "receiptlogo", type: "file", label: "Receipt Logo", size: {lg: 6}, position: 11 },
  { name: "watermark", type: "file", label: "Watermark", size: {lg: 6}, position: 12 },
  { name: "signature", type: "file", label: "Signature", size: {lg: 6}, position: 13 },
].sort((a: IField, b: IField) => a.position - b.position);