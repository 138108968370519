interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    businessCategory?:string;
    subItems ?: any;
    isHeader ?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 139,
        label: "Dashboard",
        icon: "chart-line",
        link: "/dashboard"
    },{
        id: 144,
        label: "My Businesses",
        icon: "envelope",
        link: "/businesses"
    },
    {
        id: 1,
        label: "Settings",
        isHeader : true
    },
    {
        id: 140,
        label: "Roles",
        icon: "th",
        link: "/roles"
    },
    {
        id: 146,
        label: "Backup",
        icon: "th",
        link: "/backup"
    },
    {
        id: 143,
        label: "Users",
        icon: "chart-pie",
        link: "/users"
    },
    {
        id: 144,
        label: "Inactive Users",
        icon: "chart-pie",
        link: "/users?inactive=true"
    }, {
        id: 145,
        label: "School Settings",
        businessCategory: "school",
        icon: "th",
        link: "/settings"
    },
];

export { menuItems };
