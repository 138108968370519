import { Field } from "formik";
import { Col, Label } from "reactstrap";
import { useEffect, useState } from "react";
import { ISelectProps } from "../../../interfaces/inputs/ISelectProps";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";

export const XSelect = (props: ISelectProps) => {

  const isTouched = props.touched && props.touched[props.name];
  const hasErrors = props.errors && props.errors[props.name];

  const {dataType, dataSrc, labelField, handleNewClick, allowAddNew, handleAddNew, ...rest} = props
  const [loading, setLoading] = useState<boolean>(true)
  const [options, setOptions] = useState<ISelectOption[]>(props.options || []);
  const request = new ApiCore()


  useEffect(() => {
    if(options && !dataSrc){
      setLoading(false)
    } else if (props.dataSrc){
      request.get(props.dataSrc)
        .then((response: AxiosResponse) => {

          const {data} = response

          let options: any = []

          if (Array.isArray(data)){
            options = data.map((data: any) => ({
              value: data.id,
              text: data.name || data.taxid,
              data: JSON.stringify(data)
            }))
          }else{
            if (dataType){
              options = data[dataType].map((data: any) => ({
                value: data.id,
                text: labelField ? data[labelField] : data.name || data.taxid,
                data: JSON.stringify(data)
              }))
            }
          }

          setOptions(options)
          setLoading(false)

        })
    }
  }, [props.dataSrc, props.options])

  return (
    <Col {...props.size}>
      <div className={`mb-3 ${props.icon ? "form-floating form-floating-custom" : ""}`}>

        {!props.icon && props.label &&
          <Label className={hasErrors && "text-danger"}
                 htmlFor={props.id}>{props.label}
                 {handleNewClick ? <a href="#" onClick={handleNewClick}>&nbsp;&nbsp;[Add New]</a> : null}
                 </Label>
        }

        <Field as="select"
               disabled={loading}
               {...rest}
               className={`form-select ${hasErrors && "is-invalid"}`}>
          <option>{loading ? "Loading..." : props.placeholder ? props.placeholder : "Select.."}</option>
          {allowAddNew ? <option value="-1">[Add New]</option> : null}
          {!props.options && options?.map((option: ISelectOption) => (<option value={option.value} key={option.value} data-raw={option.data}>{option.text}</option>))}
          {props.options?.map((option: ISelectOption) => (<option value={option.value} key={option.value} data-raw={option.data}>{option.text}</option>))}
        </Field>

        {props.icon &&
          <>
            <Label className={hasErrors && "text-danger"}
                   htmlFor={props.id}>{props.label}</Label>
            <div className="form-floating-icon">
              <i className={`fa ${props.icon} ${hasErrors && "text-danger"}`}></i>
            </div>
          </>
        }
      </div>

    </Col>
  );
};