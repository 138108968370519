import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { fullUrl } from "../../utils/Helpers";
import { FieldArray, Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { abilities, allModules } from "../../data/abilities";

const request = new ApiCore();

const RoleDetails = () => {
  const { id } = useParams();
  const [role, setRole] = useState<any>(null);
  const [permissions, setPermissions] = useState<any>(null);

  useEffect(() => {
    loadRole();
  }, []);

  const loadRole = () => {
    request
      .get(`${Endpoints.roles}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setRole(data.role);
        setPermissions(data.permissions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmitModules = (values: any) => {
    toast
    .promise(request.create(Endpoints.roleModules, values), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          return "Modules have been updated";
        },
      },
      error: {
        render({ data }) {
          return data;
        },
      },
    })
    .then((response: AxiosResponse) => {
      window.location.reload();
    })
  };

  const handleSubmitPermissions = (values: any) => {
    toast
    .promise(request.create(Endpoints.rolePermissions, values), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          return "Permissions have been updated";
        },
      },
      error: {
        render({ data }) {
          return data;
        },
      },
    })
    .then((response: AxiosResponse) => {
      window.location.reload();
    })
  };


  const getRoleModules = () => {
    let modules: any[] = [];
    if(role && role.rolemodules) {
      modules = role.rolemodules.map((module: any) => module.name)
    }
    return modules;
   }

   const getRolePermissions = () => {
    let permissions: any = {};
    if(role && role.permissions) {
      role.permissions.map((module: any) => {
        permissions[module.subject_class] = {}
        permissions[module.subject_class][module.action] = module.action;
      })
    }
    return permissions;
   }

  const initialModuleValues: { modules: any[], id: number } = {
    modules: getRoleModules(),
    id: id
  };

  const initialPermissionValues: { permissions?: any, id: number } = {
    id: id,
    permissions: getRolePermissions()
  };

  const isChecked = (clazz: any,action: any ,values: any) => {
    return (values.permissions && values.permissions[clazz] && values.permissions[clazz][action] && values.permissions[clazz][action] == action);
  }

  return (
    <React.Fragment>
      {permissions && role && (
        <>
          <div className="page-content">
            <ToastContainer />
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={3}>
                          <b>MODULES</b>
                          <Formik
                            initialValues={initialModuleValues}
                            enableReinitialize
                            validateOnBlur
                            onSubmit={(values: any) =>
                              handleSubmitModules(values)
                            }
                          >
                            {({
                              isSubmitting,
                              setFieldValue,
                              values,
                              errors,
                              touched,
                            }) => (
                              <>
                                <Form>
                                  <FieldArray
                                    name="modules"
                                    render={(arrayHelpers) => (
                                      <>
                                        {allModules.map(
                                          (item: any, index: number) => {
                                            return (
                                              <Col lg={12} key={index}>
                                                <XCheckbox
                                                  key={`modules.${index}`}
                                                  id={`modules.${index}`}
                                                  name={`modules`}
                                                  label={item}
                                                  value={item}
                                                  checked={values.modules && values.modules.includes(item)}
                                                  includeTopPadding={true}
                                                  onChange={(field: any,value: any) => {
                                                    if (value && value.length > 0) {
                                                      arrayHelpers.push(item);
                                                    } else {
                                                      const idx = values.modules.indexOf(item);
                                                      arrayHelpers.remove(idx);
                                                    }
                                                  }}
                                                  size={{}}
                                                />
                                              </Col>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  />
                                  <Col lg={2}>
                                    <div>&nbsp;</div>
                                    <Button
                                      disabled={false}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Form>
                              </>
                            )}
                          </Formik>
                        </Col>
                        <Col md={9}>
                          <b>{role.name}</b>
                          <Formik
                            initialValues={initialPermissionValues}
                            enableReinitialize
                            validateOnBlur
                            onSubmit={(values: any) =>
                              handleSubmitPermissions(values)
                            }
                          >
                            {({
                              isSubmitting,
                              setFieldValue,
                              values,
                              errors,
                              touched,
                            }) => (
                              <>
                                <Form>
                                  <FieldArray
                                    name="permissions"
                                    render={(arrayHelpers) => (
                                      <>
                                        {getRoleModules().map(
                                          (item: any, index: number) => {
                                            return (
                                              <>
                                                <hr/><b>{item}</b><hr/>
                                                {Object.keys(permissions[item]).map((clazz: any) => {
                                                  return (
                                                    <>
                                                    <Row key={`${clazz}-${index}`}>
                                                      <Col lg={3} key={`${clazz}-${index}-hd`}>{clazz}</Col>
                                                      {permissions[item][clazz].map((action: any) => {
                                                        return (
                                                          <Col key={`${clazz}-${action.name}-${index}-cbx`}>
                                                          <XCheckbox
                                                            key={`permissions.${clazz}.${clazz}.${action.cancan_action}`}
                                                            id={`permissions.${clazz}.${action.cancan_action}`}
                                                            name={`permissions.${clazz}.${action.cancan_action}`}
                                                            label={action.name}
                                                            value={action.cancan_action}
                                                            checked={isChecked(clazz,action.cancan_action,values)}
                                                            includeTopPadding={false}
                                                            onChange={setFieldValue}
                                                            size={{}}
                                                          />
                                                        </Col>
                                                        )
                                                      })}
                                                    </Row><br/>
                                                    </>
                                                  )
                                                })}
                                              </>
                                            );
                                          }
                                        )}
                                      </>
                                    )}
                                  />
                                  <Col lg={2}>
                                    <div>&nbsp;</div>
                                    <Button
                                      disabled={false}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Form>
                              </>
                            )}
                          </Formik>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default RoleDetails;
