export const abilities: any = {
  "Academics": {
    "School::Student": ["manage","create","read","update","delete"],
    "School::Mark": ["manage","create","read","update","delete"],
    "School::Marktype": ["manage","create","read","update","delete"],
    "School::Subject": ["manage","create","read","update","delete"]
  },"Medical": {
    "Hospital::Patient": ["manage","create","read","update","delete"]
  },
}

export const allModules = [
  "Accounting",
  "Academics",
  "Human Resource",
  "Inventory",
  "Medical",
]