export const Endpoints = {
  login: "/auth/login",
  signup: "/auth/signup",
  products: "/api/products",
  efris_products: "/api/products/efris",
  productGroups: "/api/productgroups",
  accounts: "/api/accounts",
  invoices: "/api/invoices",
  stockrecords: "/api/stockrecords",
  receipts: "/api/receipts",
  memos: "/api/memos",
  vouchers: "/api/vouchers",
  defaults: "/api/invoices/defaults",
  companies: "/api/companies",
  currencies: '/api/currencies',
  taxes: '/api/taxes',
  whduties: '/api/whduties',
  locations: '/api/locations',
  countries: "/api/countries",
  banks:"/api/banks",
  schoolStudentCategories:"/api/school/studentcategories",
  tenants:"/api/tenants",
  roles:"/api/roles",
  roleModules:"/api/roles/modules",
  rolePermissions:"/api/roles/permissions",
  users:"/api/users",
  userProfile:"/api/users/profile",
  home:"/api/home/index",
  subjects:"/api/school/subjects",
  marktypes:"/api/school/marktypes",
  marks:"/api/school/marks",
  marksGrid:"/api/school/marks/grid",
  terminalreport:"/api/school/terminalreport",
  markSheet:"/api/school/marks/marksheet",
  remarktypes:"/api/school/remarktypes",
  students:"/api/school/students",
  grades:"/api/school/grades",
  proportions:"/api/school/proportions",
  alevelscores:"/api/school/alevelscores",
  automarkcomments:"/api/school/automarkcomments",
  markcomments:"/api/school/markcomments",
  remarks:"/api/school/remarks",
  autoreportcomments:"/api/school/autoreportcomments",
  schoolSettings:"/api/school/settings",
  backup:"/api/tenants/backup",
}