import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { SettingFields } from "../../fields/SettingFields";
import { Endpoints } from "../../utils/Endpoints";
import { ISetting } from "../../interfaces/ISetting";
import { SettingSchema } from "../../schemas/SettingSchema";

const Settings = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const [settings, setSettings] = useState<any>([])
  const [showSettingDialog, setShowSettingDialog] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>("New Business")
  const [setting, setSetting] = useState<any>()

  const history = useHistory()

  const handleSettingEdit = async (id: string) => {
    const { data } = await request.get(`${Endpoints.schoolSettings}/${id}`, {})
    setSetting(data)
    setShowSettingDialog(true);
  }

  const handleOption = (type: string, id: any) => {
    if (type == "new") {
      setSetting({
        name: "",
        settingcode: ""
      })
      setModalTitle("Add Business");
      setShowSettingDialog(true);
    } else if (type == "edit") {
      setModalTitle("Edit Business details");
      handleSettingEdit(id);
    } else if (type == "details") {
      history.push("/businesses/" + id);
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "category",
      text: "Type"
    },
    {
      dataField: "year",
      text: "Year"
    },
    {
      dataField: "term",
      text: "Term"
    },
    {
      dataField: "motto",
      text: "Motto"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, setting: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption("edit",setting.id)}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Edit
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption("details",setting.id)}>
            <i className="mdi mdi-folder-open font-size-16 text-success me-1"></i>Details
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: settings.length,
    totalSize: settings.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();

  useEffect(() => {

    request.get(Endpoints.schoolSettings, {})
      .then((response: AxiosResponse) => {
        const { settings, totalRecords } = response.data;

        setSettings(settings);
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const initialValues: ISetting = {
    name: "",
    address: ""
  };

  const handleUpdate = (setting: ISetting) => {

    const updatedSettings = settings.map((p: ISetting) => {
      if (p.id === setting.id){
        return {...p, ...setting}
      }

      return p
    })

    setSettings(updatedSettings)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings - Treda</title>
        </MetaTags>

        <Container fluid>

          <XModal title={modalTitle}
                  width={"lg"}
                  open={showSettingDialog}
                  toggle={() => setShowSettingDialog(!showSettingDialog)}
                  onClose={() => setShowSettingDialog(false)}>

            <CreateForm
              fields={SettingFields}
              initialValues={setting}
              submitEndpoint={Endpoints.schoolSettings}
              submitButtonLabel={"Save"}
              validationSchema={SettingSchema}
              formData={true}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowSettingDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Settings
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption("new",null)}>
                        Add setting
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading settings. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={settings}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Settings;