import { Form, Formik } from "formik";
import { IFormProps } from "../../interfaces/IFormProps";
import { Button, Col, Row } from "reactstrap";
import { XTextBox } from "../common/inputs/XTextBox";
import React, { useEffect, useState } from "react";
import { XTextArea } from "../common/inputs/XTextArea";
import { XSelect } from "../common/inputs/XSelect";
import { XCheckbox } from "../common/inputs/XCheckbox";
import resolveBaseUrl from "../../services";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { AxiosError, AxiosResponse } from "axios";
import { XFileInput } from "../common/inputs/XFileInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const request = new ApiCore();

const CreateForm = (props: IFormProps) => {
  const {
    initialValues,
    validationSchema,
    handleUpdate,
    onClose,
    fields,
    formData,
    submitEndpoint,
    debug,
  } = props;

  const handleSubmit = (values: any) => {
    if (submitEndpoint) {
      if (values.id) {
        if (formData) {
          toast
            .promise(request.updateFormData(`${submitEndpoint}/${values.id}`, values), {
              pending: "Please wait..",
              success: {
                render({ data }) {
                  return "The record has been updated successfully";
                },
              },
              error: {
                render({ data }) {
                  return data;
                },
              },
            })
            .then((response: AxiosResponse) => {
              if (handleUpdate) handleUpdate(response.data);
              if (onClose) onClose();
            })
            .catch((error: AxiosError) => {
              console.log(error);
            });
        } else {
          toast
            .promise(request.update(`${submitEndpoint}/${values.id}`, values), {
              pending: "Please wait..",
              success: {
                render({ data }) {
                  return "The record has been updated successfully";
                },
              },
              error: {
                render({ data }) {
                  return data;
                },
              },
            })
            .then((response: AxiosResponse) => {
              if (handleUpdate) handleUpdate(response.data);
              if (onClose) onClose();
            })
            .catch((error: AxiosError) => {
              console.log(error);
            });
        }
      } else {
        if (formData) {
          toast
            .promise(request.createFormData(submitEndpoint, values), {
              pending: "Please wait..",
              success: {
                render({ data }) {
                  return "The record has been created successfully";
                },
              },
              error: {
                render({ data }) {
                  return data;
                },
              },
            })
            .then((response: AxiosResponse) => {
              if (handleUpdate) handleUpdate(response.data);
              if (onClose) onClose();
            })
            .catch((error: AxiosError) => {
              console.log(error);
            });
        } else {
          toast
          .promise(request.create(submitEndpoint, values), {
            pending: "Please wait..",
            success: {
              render({ data }) {
                return "The record has been created successfully";
              },
            },
            error: {
              render({ data }) {
                return data;
              },
            },
          })
          .then((response: AxiosResponse) => {
            if (handleUpdate) handleUpdate(response.data);
            if (onClose) onClose();
          })
          .catch((error: AxiosError) => {
            console.log(error);
          });
        }
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({ isSubmitting, values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="modal-body">
            <ToastContainer />
            <Row>
              {fields.map((field: any) => {
                if (field.type === "select") {
                  return (
                    <XSelect
                      key={field.name}
                      id={field.name}
                      {...field}
                      touched={touched}
                      errors={errors}
                    />
                  );
                } else if (field.type === "textarea") {
                  return (
                    <XTextArea
                      key={field.name}
                      id={field.name}
                      {...field}
                      touched={touched}
                      errors={errors}
                    />
                  );
                } else if (field.type === "file") {
                  return (
                    <XFileInput
                      key={field.name}
                      id={field.name}
                      {...field}
                      touched={touched}
                      errors={errors}
                      onChange={(event: any) => {
                        setFieldValue(field.name, event.currentTarget.files[0]);
                      }}
                    />
                  );
                } else if (field.type === "checkbox") {
                  return (
                    <XCheckbox
                      key={field.name}
                      id={field.name}
                      {...field}
                      onChange={setFieldValue}
                      checked={values[field.name]}
                    />
                  );
                } else {
                  return (
                    <XTextBox
                      key={field.name}
                      id={field.name}
                      {...field}
                      errors={errors}
                      touched={touched}
                    />
                  );
                }
              })}

              {debug && (
                <Col xs={12}>
                  <pre
                    className={"code"}
                    style={{ width: "100%", height: "100%" }}
                  >
                    {JSON.stringify({ values, errors, touched }, null, 2)}
                  </pre>
                </Col>
              )}
            </Row>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-light" onClick={onClose}>
              Close
            </button>
            <Button type="submit" className="btn btn-primary">
              {props.submitButtonLabel || "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateForm;
