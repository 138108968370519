import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { fullUrl } from "../../utils/Helpers";

const request = new ApiCore();

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    request
      .get(`${Endpoints.users}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setUser(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  return (
    <React.Fragment>
      {user && (
        <>
          <div className="page-content">
            <ToastContainer />
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <img
                            src={fullUrl(user.path)}
                            alt="Photo"
                            height="200"
                          />
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{user.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{user.email}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Staff No.</div>
                              <div className="dt-value">{user.staffno}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Default Module</div>
                              <div className="dt-value">{user.defaultmodule}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">&nbsp;</div>
                              <div className="dt-value">{user.payroll ? <i className="mdi mdi-check-circle font-size-16 text-primary me-1"></i> : <i className="mdi mdi-cancel font-size-16 text-primary me-1"></i>} Payroll</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{user.phone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">&nbsp;</div>
                              <div className="dt-value">{user.payroll ? <i className="mdi mdi-check-circle font-size-16 text-primary me-1"></i> : <i className="mdi mdi-cancel font-size-16 text-primary me-1"></i>} Admin</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Initials</div>
                              <div className="dt-value">{user.initials}</div>
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            {user.roles.map((role: any) => {
                              return <Col md={3}>
                                <i className="mdi mdi-check-circle font-size-16 text-primary me-1"></i>
                                {role.name}
                              </Col>
                            })}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default UserDetails;
