import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { toast } from "react-toastify";
import { currentTenant } from "../../utils/Helpers";

const Backup = () => {

  const [loading, setLoading] = useState<boolean>(true)
  const request = new ApiCore();

  useEffect(() => {
    setLoading(true);
    request.createFormData(Endpoints.backup, {}, 'arraybuffer')
      .then((response: AxiosResponse) => {
        toast.success('File is ready for download');
        setLoading(false);
        let fileName = currentTenant().tenantcode + ".sql"
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Backup - Treda</title>
        </MetaTags>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Backup
                  </CardTitle>


                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Please wait..." />}
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    </React.Fragment>
  );
};

export default Backup;